dmx.Component('bs4-toasts', {

  attributes: {
    position: {
      type: String,
      default: 'top',
      enum: ['top', 'bottom'],
    },

    align: {
      type: String,
      default: 'right',
      enum: ['left', 'right'],
    },

    offsetX: {
      type: Number,
      default: 15,
    },

    offsetY: {
      type: Number,
      default: 15,
    },

    minWidth: {
      type: Number,
      default: 240,
    },

    maxWidth: {
      type: Number,
      default: 350,
    },

    zIndex: {
      type: Number,
      default: 10000,
    },

    delay: {
      type: Number,
      default: 5000,
    },
  },

  methods: {
    clear () {
      this._clear();
    },

    show (options) {
      this._show(options);
    },
  },

  render (node) {
    this._setup();
  },

  performUpdate (updatedProps) {
    if (!dmx.equal(props, this.props)) {
      this._setup();
    }
  },

  _setup () {
    dmx.bs4toast.setup(this.props);
  },

  _clear () {
    dmx.bs4toast.clear();
  },

  _show (options) {
    // options: title, message, delay, closable
    dmx.bs4toast.show(options);
  },

});
