dmx.Actions({

  'toast.setup' (options) {
    dmx.bs4toast.setup(this.parse(options));
  },

  'toast.show' (options) {
    dmx.bs4toast.show(this.parse(options));
  },

  'toast.clear' () {
    dmx.bs4toast.clear();
  },
  
});
